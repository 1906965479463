import { BaseConfig } from '@danone-global/internal/react/core'
import { messages } from '@danone-global/internal/react/modules/checkout'
import React from 'react'

export const baseEnvironment: BaseConfig = {
  storeName: 'NUTRIKLUBSK',

  siteName: 'sk-nutriklub',

  siteSlug: 'sk-nutriklub',

  defaultLocale: 'sk-SK',

  providerId: 'danis_eu',

  ctHost: 'https://api.europe-west1.gcp.commercetools.com',

  ctAuthHost: 'https://auth.europe-west1.gcp.commercetools.com',

  ctStoreKey: 'NUTRIKLUBSK',

  localeConfigs: [
    {
      locale: 'sk-SK',
      country: 'SK',
      currency: 'EUR',
      initMessages: () =>
        import('../translations/sk_SK.json').then((r) => r.default),
      urls: {
        faq: '/produkty-a-nakup/casto-kladene-otazky-produkty.html',
        privacy: '/vyhlasenie-o-ochrane-osobnych-udajov.html',
        dataCollection: '/data-collection.html',
        termsAndConditions: '/obchodne-podmienky.html',
        contact: '/kontakt.html',
      },
    },
  ],

  analytics: {
    tracker: 'aem',
    language: 'sk-SK',
    defaultCategory: 'Baby food',
  },

  overrides: {
    'shopping-cart-v2': {
      variant: 'badge',
    },
  },

  checkout: {
    stepAddress: React.lazy(
      () =>
        // imports should be on one line, in order to trigger transifex extract actions!
        // prettier-ignore
        import('@danone-global/internal/react/components/checkout/sk-step-address'),
    ),
    payment: React.lazy(
      () =>
        // prettier-ignore
        import('@danone-global/internal/react/components/payment-providers/adyen-drop-in'),
    ),

    checkboxes: [
      {
        name: 'isMarketingOptedIn',
        required: false,
        store: true,
        label: messages.isMarketingOptedIn,
      },
      {
        name: 'agreeTerms',
        required: true,
        store: false,
        label: messages.agreeTerms,
      },
    ],
  },

}
